<template lang="pug">
  .login-wrapper
    .login-container
      //- Register
</template>

<script>
//- import Register from '../ui/Register'
export default {
  name: 'RegisterView',
  components: {
    //- Register
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.login-wrapper {
  background-color: #222;
  width: 100%; height: 100%;
  position: absolute;
}
.login-container {
  position: absolute; left: 50%; top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.card {
  width: 400px;
  margin: -250px -200px;
  position: absolute;
  top: 50%; left: 50%;
}
</style>
